import React, { useEffect } from 'react';
import './About.css';
import { Trans, useTranslation } from 'react-i18next';
import EinoImage from '../../images/Eino_Itkonen-300x300.jpg';
import UulaImage from '../../images/Uula_Hursti-300x300.jpg';
import RikuImage from '../../images/Riku_Rainevuo-800x800.jpg';

// Mouse tracking variables
let mouseX = 0;
let mouseY = 0;
let holeX = 0;
let holeY = 0;
const maxSpeed = 15;
const followFactor = 0.05;

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleMouseMove = (e) => {
      mouseX = e.clientX + window.scrollX;
      mouseY = e.clientY + window.scrollY;
    };

    const updateBorders = () => {
      const aboutContainer = document.querySelector('.about-container');
      if (!aboutContainer) return;
      const elements = aboutContainer.querySelectorAll('.owner, .description-wrapper'); // Target both owners and description
      const scrollX = window.scrollX;
      const scrollY = window.scrollY;
      const dx = mouseX - holeX;
      const dy = mouseY - holeY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const speed = Math.min(maxSpeed, distance * followFactor);
      if (distance > 0) {
        holeX += (dx / distance) * speed;
        holeY += (dy / distance) * speed;
      }
      elements.forEach((element) => {
        const coverLayer = element.querySelector('.cover-layer');
        if (!coverLayer) return;
        const rect = element.getBoundingClientRect();
        const elementX = rect.left + scrollX;
        const elementY = rect.top + scrollY;
        const relX = holeX - elementX;
        const relY = holeY - elementY;
        const radius = 200;
        const isNearBorder =
          relX >= -radius && relX <= rect.width + radius &&
          relY >= -radius && relY <= rect.height + radius;
        if (isNearBorder) {
          const mask = `radial-gradient(circle 250px at ${relX}px ${relY}px, transparent 40%, black 70%)`;
          coverLayer.style.maskImage = mask;
          coverLayer.style.webkitMaskImage = mask;
        } else {
          coverLayer.style.maskImage = 'none';
          coverLayer.style.webkitMaskImage = 'none';
        }
      });
      requestAnimationFrame(updateBorders);
    };

    document.addEventListener('mousemove', handleMouseMove);
    requestAnimationFrame(updateBorders);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      const elements = document.querySelectorAll('.owner, .description-wrapper');
      elements.forEach((element) => {
        const coverLayer = element.querySelector('.cover-layer');
        if (coverLayer) {
          coverLayer.style.maskImage = 'none';
          coverLayer.style.webkitMaskImage = 'none';
        }
      });
    };
  }, []);

  return (
    <div className="about-container">
      <h1 className="about-title" style={{ color: 'var(--main-color)' }}>{t('about.title')}</h1>
      <div className="description-wrapper">
        <div className="gradient-ring"></div>
        <div className="cover-layer"></div>
        <p className="about-description">
          <Trans i18nKey="about.description"></Trans>
        </p>
      </div>
      <div className="owners">
        <div className="owner">
          <div className="photo-frame">
            <div className="gradient-ring"></div>
            <div className="cover-layer"></div>
            <img src={EinoImage} alt={t('about.eino.name')} className="owner-image" />
          </div>
          <p className="owner-info">
            <strong className="owner-name">{t('about.eino.name')}</strong><br />
            {t('about.eino.title1')}<br />
            {t('about.eino.title2')}<br />
            {t('about.eino.email')}<br />
            {t('about.eino.phone')}
          </p>
        </div>
        <div className="owner">
          <div className="photo-frame">
            <div className="gradient-ring"></div>
            <div className="cover-layer"></div>
            <img src={UulaImage} alt={t('about.uula.name')} className="owner-image" />
          </div>
          <p className="owner-info">
            <strong className="owner-name">{t('about.uula.name')}</strong><br />
            {t('about.uula.title1')}<br />
            {t('about.uula.title2')}<br />
            {t('about.uula.email')}<br />
            {t('about.uula.phone')}
          </p>
        </div>
        <div className="owner">
          <div className="photo-frame">
            <div className="gradient-ring"></div>
            <div className="cover-layer"></div>
            <img src={RikuImage} alt={t('about.riku.name')} className="owner-image" />
          </div>
          <p className="owner-info">
            <strong className="owner-name">{t('about.riku.name')}</strong><br />
            {t('about.riku.title1')}<br />
            {t('about.riku.email')}<br />
            {t('about.riku.phone')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;