import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './DesignAutomation.css';
import demoVideo from '../../images/Automatisointi_Demo.mp4';
import Configurator from '../Configurator/Configurator';

let mouseX = 0;
let mouseY = 0;
let holeX = 0;
let holeY = 0;
const maxSpeed = 15;
const followFactor = 0.05;

const DesignAutomation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const handleMouseMove = e => {
      mouseX = e.clientX + window.scrollX;
      mouseY = e.clientY + window.scrollY;
    };

    const updateBorders = () => {
      const designAutomation = document.querySelector('.design-automation');
      if (!designAutomation) return;
      const boxes = designAutomation.querySelectorAll('.content-box, .video-container-automation');
      const scrollX = window.scrollX;
      const scrollY = window.scrollY;
      const dx = mouseX - holeX;
      const dy = mouseY - holeY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const speed = Math.min(maxSpeed, distance * followFactor);
      if (distance > 0) {
        holeX += (dx / distance) * speed;
        holeY += (dy / distance) * speed;
      }
      boxes.forEach(box => {
        const whiteMask = box.querySelector('.white-mask');
        if (!whiteMask) return;
        const rect = box.getBoundingClientRect();
        const boxX = rect.left + scrollX;
        const boxY = rect.top + scrollY;
        const relX = holeX - boxX;
        const relY = holeY - boxY;
        const radius = 300;
        const isNearBorder =
          relX >= -radius && relX <= rect.width + radius &&
          relY >= -radius && relY <= rect.height + radius;
        if (isNearBorder) {
          const mask = `radial-gradient(circle 250px at ${relX}px ${relY}px, transparent 40%, black 70%)`;
          whiteMask.style.maskImage = mask;
          whiteMask.style.webkitMaskImage = mask;
        } else {
          whiteMask.style.maskImage = 'none';
          whiteMask.style.webkitMaskImage = 'none';
        }
      });
      requestAnimationFrame(updateBorders);
    };

    document.addEventListener('mousemove', handleMouseMove);
    requestAnimationFrame(updateBorders);

    const page = document.querySelector('.design-automation');
    if (page) {
      const handleRouteChange = () => {
        page.classList.add('fade-out');
        setTimeout(() => navigate(location.pathname, { replace: true }), 500);
      };
      const links = document.querySelectorAll('a');
      links.forEach(link => {
        link.addEventListener('click', e => {
          e.preventDefault();
          handleRouteChange();
          setTimeout(() => navigate(link.getAttribute('href'), { replace: true }), 500);
        });
      });
      return () => {
        links.forEach(link => link.removeEventListener('click', handleRouteChange));
        document.removeEventListener('mousemove', handleMouseMove);
        const boxes = document.querySelectorAll('.content-box, .video-container-automation');
        boxes.forEach(box => {
          const whiteMask = box.querySelector('.white-mask');
          if (whiteMask) {
            whiteMask.style.maskImage = 'none';
            whiteMask.style.webkitMaskImage = 'none';
          }
        });
      };
    }
  }, [navigate, location.pathname]);

  const handleVideoClick = () => setIsVideoOpen(true);

  const handleCloseVideo = () => {
    setIsFading(true);
    setTimeout(() => {
      setIsVideoOpen(false);
      setIsFading(false);
    }, 500);
  };

  const scrollToBottom = () =>
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });

  return (
    <div className="design-automation">
      {isVideoOpen && (
        <div className={`video-overlay ${isFading ? 'fade-out' : 'fade-in'}`} onClick={handleCloseVideo}>
          <video className="expanded-video" controls>
            <source src={demoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <div className="navigation-arrows">
        <Link to="/mekaniikkasuunnittelu" className="left-arrow">
          〈 <span className="hover-text left-hover">{t('designAutomation.mechanicalDesign')}</span>
        </Link>
        <h1 className="main-title">{t('designAutomation.title')}</h1>
        <Link to="/3d-tulostus" className="right-arrow">
          〉 <span className="hover-text right-hover">{t('designAutomation.3dPrinting')}</span>
        </Link>
      </div>
      <hr className="section-divider" />
      <div className="content-box intro">
        <div className="orange-border"></div>
        <div className="white-mask"></div>
        <p>{t('designAutomation.intro')}</p>
      </div>
      <button className="demo-button" onClick={scrollToBottom}>
        {t('designAutomation.demo')}
      </button>
      <div className="video-wrapper-automation">
        <div className="video-container-automation">
          <div className="orange-border"></div>
          <div className="white-mask"></div>
          <video
            className="design-video"
            width="480"
            autoPlay
            muted
            loop
            playsInline
            onClick={handleVideoClick}
          >
            <source src={demoVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="content-box solution">
        <div className="orange-border"></div>
        <div className="white-mask"></div>
        <h2>{t('designAutomation.solutionTitle')}</h2>
        <p>{t('designAutomation.solutionText')}</p>
      </div>
      <div className="content-box configurator">
        <div className="orange-border"></div>
        <div className="white-mask"></div>
        <h2>{t('designAutomation.configuratorTitle')}</h2>
        <p>{t('designAutomation.configuratorText')}</p>
      </div>
      <div className="content-box custom">
        <div className="orange-border"></div>
        <div className="white-mask"></div>
        <h2>{t('designAutomation.customTitle')}</h2>
        <p>{t('designAutomation.customText')}</p>
        <p className="highlight">{t('designAutomation.partnerCall')}</p>
      </div>
      <div className="content-box closing">
        <div className="orange-border"></div>
        <div className="white-mask"></div>
        <p>{t('designAutomation.closing')}</p>
      </div>
      <hr className="section-divider" />
      <div style={{ margin: '5px 0', textAlign: 'center' }}>
        <Configurator />
      </div>
    </div>
  );
};

export default DesignAutomation;